import React from 'react';

import { SortField, FilterField } from '../../../../components/Table';

const Component = ({ ViewModal, FormModal, list, total, filters, sort, handlers }) => (
  <div className="col-md-12">
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">Subscriptions (<b className="text-info">{list.length} : {total}</b>)</h5>
          <div>
            <button className="btn btn-round btn-info" onClick={handlers.onOpenSubscriptionCreate}>
              <i className="nc-icon nc-simple-add"/>
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-small">
            <thead className="text-primary">
            <tr>
              <SortField className="col-auto text-center" name="owner" label="Owner" sort={sort} handlers={handlers}/>
              <th className="col-auto text-center text-secondary">Monthly</th>
              <th className="col-auto text-center text-secondary">Amount</th>
              <th className="col-auto text-center text-secondary">With VAT</th>
              <th className="col-auto text-center text-secondary">From</th>
              <th className="col-auto text-center text-secondary">Last payment</th>
            </tr>
            <tr>
              <FilterField name="email" filters={filters} handlers={handlers}/>
              <th/>
              <th/>
              <th/>
              <th/>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {list && list.map((data, idx) => (
              <tr key={idx} onClick={handlers.onOpenSubscription(data)}>
                <td className="text-left col-auto pl-3">{data.email}</td>
                <td className="text-right col-auto pr-3">{data?.current.toFixed(2)}€</td>
                <td className="text-right col-auto pr-3">
                  {data.totals.amount.toFixed(2)}€
                </td>
                <td className="text-right col-auto pr-3">
                  {data.totals.total.toFixed(2)}€
                </td>
                <td className="text-center col-auto text-nowrap">{data.totals.from}</td>
                <td className="text-center col-auto">-</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    {ViewModal}
    {FormModal}
  </div>
);

export default Component;
