const ENTITY_TYPE_MAP = {
  ACCOUNT: 'Programėlė',
  MOBILE_DATA: 'Ryšio paslaugos mokestis',
  TEMP_SENSOR_HOUR: 'Temperatūros jutikliai (val.)',
  TEMP_SENSOR_MIN: 'Temperatūros jutikliai (min.)',
  VENT: 'Ventiliatorių valdikliai',
  METEO: 'Meteo (20 min.)',
  UNI_HOUR: 'Valandinis jutiklis',
  UNI_MIN: 'Minutinis jutiklis',
  DISCOUNT: 'Nuolaida',
};

module.exports = {
  ENTITY_TYPE_MAP,
}